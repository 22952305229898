import { authRequest } from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    template: {},
  },
  getters: {

  },
  mutations: {
    SET_MY_TEMPLATE(state, template) {
        state.template = template
    },
  },
  actions: {
    updateTemplate({ dispatch }, { templateID, project, comment, type_market }) {
      return new Promise((resolve, reject) => {
        authRequest
          .put(`${BASE_URL}template/${templateID}/`, { project, comment, type_market })
          .then(res => {
            dispatch('Templates/GET_ALL_TEMPLATES', null, { root: true })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    loadTemplate({ commit, dispatch }, templateID) {
      return new Promise((resolve, reject) => {
        authRequest
          .get(`${BASE_URL}template/${templateID}`)
          .then(response => {
            commit('SET_MY_TEMPLATE', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    setNewTemplate({ commit, dispatch }, { project, comment, type_market}) {
        return new Promise((resolve, reject) => {  
          authRequest
            .post(`${BASE_URL}template/`, { project, comment, type_market })
            .then(res => {
              commit('SET_MY_TEMPLATE', res)
              resolve(res)
            })
            .catch(error => reject(error))
        })
      },
    deleteTemplate({ dispatch }, { templateID }) {
      return new Promise((resolve, reject) => {
        authRequest
          .delete(`${BASE_URL}template/${templateID}`)
          .then(res => {
            dispatch('Templates/GET_ALL_TEMPLATES', null, { root: true })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
  },
}

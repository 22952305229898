export default [
	// *===============================================---*
	// *--------- Projects ---- ---------------------------------------*
	// *===============================================---*
	{
		path: '/templates',
		name: 'templates',
		component: () => import('@/views/apps/templates/templates-list/TemplatesList.vue'),
		meta: {
			pageTitle: 'Templates',
			breadcrumb: [
				{
					text: 'Pages',
				},
				{
					text: 'Templates',
					active: true,
				},
			],
		},
	},
	{
		path: '/all-licenses',
		name: 'all-licenses',
		component: () => import('@/views/apps/licenses/LicensesList.vue'),
		meta: {
			pageTitle: 'All licenses',
			breadcrumb: [
				{
					text: 'Pages',
				},
				{
					text: 'All licenses',
					active: true,
				},
			],
		},
	},
	{
		path: '/license-edit/:licenseID/:',
		name: 'license-edit',
		component: () => import('@/views/apps/license/LicenseEdit.vue'),
		meta: {
			pageTitle: 'User licenses',
			breadcrumb: [
				{
					text: 'Pages',
				},
				{
					text: 'User licenses',
					active: true,
				},
			],
		},
	},
	{
		path: '/templates/edit/:templateID/:',
		name: 'apps-templates-edit',
		component: () => import('@/views/apps/templates/TemplatesEdit.vue'),
	},
	{
		path: '/projects',
		name: 'projects',
		component: () => import('@/views/apps/user/users-list/UsersList.vue'),
		meta: {
			pageTitle: 'Projects',
			breadcrumb: [
				{
					text: 'Projects',
					active: true,
				},
			],
		},
	},
	{
		path: '/projects/view/:projectId/:',
		name: 'apps-users-view',
		component: () => import('@/views/apps/user/users-view/UsersView.vue'),
	},

	{
		path: '/projects/edit/:projectId/:',
		name: 'apps-users-edit',
		component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
	},

	{
		path: '/editor/:projectId/:editorId/:',
		name: 'apps-editor-edit',
		component: () => import('@/views/apps/editor/EditorEdit.vue'),
	},

	{
		path: '/project-general/:projectId/:',
		name: 'project-setting-general',
		component: () => import('@/views/pages/project-setting-general/ProjectSettingGeneral'),
		meta: {
			pageTitle: 'Project Setting',
			breadcrumb: [
				{
					text: 'General',
				},
			],
		},
	},

	{
		path: '/effects/:projectId/:editorId/:',
		name: 'effects',
		component: () => import('@/views/pages/effects/Effects.vue'),
		meta: {
			pageTitle: 'Editor effects',
		},
	},

	{
		path: '/movements/:projectId/:editorId/:',
		name: 'movements',
		component: () => import('@/views/pages/movements/Movements.vue'),
		meta: {
			pageTitle: 'Editor movements',
		},
	},

	{
		path: '/code/:projectId/:editorId/:',
		name: 'code',
		component: () => import('@/views/pages/code/Code.vue'),
		meta: {
			pageTitle: 'Editor code',
		},
	},

	{
		path: '/project-hosting/:projectId/:',
		name: 'project-setting-hosting',
		component: () => import('@/views/pages/project-setting-hosting/ProjectSettingHosting'),
		meta: {
			pageTitle: 'Project Setting',
			breadcrumb: [
				{
					text: 'Hosting',
				},
			],
		},
	},
	{
		path: '/editor-back-env/:projectId/:editorId/:',
		name: 'background-environment',
		component: () => import('@/views/pages/background-environment/BackgroundEnvironment'),
		meta: {
			pageTitle: 'Background / Environment',
			breadcrumb: [
				{
					text: 'Background',
				},
			],
		},
	},
	{
		path: '/editor-ar-vr/:projectId/:editorId/:',
		name: 'editor-ar-vr',
		component: () => import('@/views/apps/editor/EditorARVR'),
		meta: {
			pageTitle: 'Editor AR/VR',
			breadcrumb: [
				{
					text: 'Edit AR/VR',
				},
			],
		},
	},
	{
		path: '/interface/:projectId/:editorId/:',
		name: 'interface',
		component: () => import('@/views/apps/editor/Interface'),
		meta: {
			pageTitle: 'Editor Inerface',
			breadcrumb: [
				{
					text: 'Edit Interface',
				},
			],
		},
	},
	{
		path: '/editor/:projectId/:editorId/:',
		name: 'editor-frequency',
		component: () => import('@/views/apps/editor/EditorFrequency.vue'),
		meta: {
			pageTitle: 'Editor Frequency',
			breadcrumb: [
				{
					text: 'Editor Frequency',
					active: true,
				},
			],
		},
	},

	{
		path: '/analytics-statistics/:projectId/:',
		name: 'analytics-statistics',
		component: () => import('@/views/apps/analytics/AnalyticsStatistics.vue'),
	},

	{
		path: '/team/:projectId/:',
		name: 'team-list',
		component: () => import('@/views/apps/team/team-list/TeamList'),
	},
	{
		path: '/analytics-simulation/:projectId/:',
		name: 'analytics-simulation',
		component: () => import('@/views/apps/analytics/AnalyticsSimulation.vue'),
	},
	{
		path: '/source-files/:projectId/:editorId/:',
		name: 'source-files',
		component: () => import('@/views/apps/source-files/SourceFiles.vue'),
	},
];

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import UserData from './user-data'
import Projects from './projects'
import Project from './project'
import Profile from './profile'
import Editor from './editor'
import Analytics from './analitics'
import Team from './team'
import Notification from './notification'
import Templates from './templates'
import Template from './template'
import Effects from './effects'
import Effect from './effect'
import Licenses from './licenses'
import License from './license'
import SourceFiles from './source-files'
import SourceFile from './source-file'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    UserData,
    Projects,
    Project,
    Profile,
    Editor,
    Analytics,
    Team,
    Notification,
    Templates,
    Template,
    Effects,
    Effect,
    Licenses,
    License,
    SourceFiles,
    SourceFile,
  },
  plugins: [createPersistedState()],
  strict: process.env.DEV,
})

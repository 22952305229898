import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import ToastificationContent from "@core/components/toastification/ToastificationContent"

import Vue from 'vue'
import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)

import router from '@/router'

const tokenRequest = axios.create({
	baseURL: process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL,
	timeout: 5000,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
	withCredentials: true,
})

const refreshToken = () => {
	const refreshBody = {'refresh': localStorage.getItem('refreshValidToken')}
	return tokenRequest
		.post('auth/jwt/refresh/', refreshBody)
		.then(res => {
			useJwt.setToken(res.data.access)
			axios.defaults.headers.common.Authorization = `Bearer ${res.data.access}`
			return Promise.resolve(res.data)
		})
		.catch(error => Promise.reject(error))
}

const isCorrectRefreshError = status => status === 401

export const confirmRequest = axios.create({
	baseURL: process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json'
	},
	withCredentials: true,
})

export const authRequest = axios.create({
	baseURL: process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL,
	headers: {
		Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
	withCredentials: true,
})

export const simpleAuthRequest = axios.create({
	baseURL: process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL,
	headers: {
		Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
	withCredentials: true,
})

export const simpleRequest = axios.create({
	baseURL: process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
	},
	withCredentials: true,
})

confirmRequest.interceptors.response.use(
	response => {
		const vm = new Vue()
		let textMessage = ''
		let titleText = response.statusText
		if (response.data) {
			for (const property in response.data) {
				textMessage = response.data[property]
			}
		} else {
			textMessage = 'Request has been successful'
		}

		vm.$bvToast.toast(textMessage, {
			title: titleText,
			variant: 'success',
			autoHideDelay: 5000,
			icon: "CoffeeIcon",
		})
		return response
	},
	error => {
		const vm = new Vue()
		let textMessage = ''
		let titleText = error.response.statusText
		if (error.response.data) {
			for (const property in error.response.data) {
				textMessage = error.response.data[property]
			}
		} else {
			textMessage = 'Request failed'
		}

		vm.$bvToast.toast(textMessage, {
			title: titleText,
			variant: 'danger',
			autoHideDelay: 5000,
			icon: "AlertTriangleIcon",
		})
		return (error)
	}
)

authRequest.interceptors.response.use(
	response => {
		if (response.status === 401) {
			// logoutUser()
		}
		return response
	},
	error => {
		if (error.response && error.response.status === 401) {
			return Promise.reject(error)
		}
		const vm = new Vue()
		let text = '';
		if (error.response && error.response.status === 404) {
			text = error.response.statusText;
		} else if (error.response) {
			text = JSON.stringify(error.response.data.detail);
		}
		const h = vm.$createElement
		// Increment the toast count

		// Create the message
		const vNodesMsg = h(
			'div',
			{ class: ['Vue-Toastification__toast', ' Vue-Toastification__toast--default'] },
			[
				h('div', { class: ['Vue-Toastification__toast-component-body'] }, [

					h('div', { class: ['toastification'] }, [

						h('small', { class: ['d-inline-block', 'text-body'] }, [

						]),
						`${text}`,
					])
				]),

			]
		)
		const vTitle = h('h5', { class: ['mb-0', 'font-weight-bolder', 'toastification-title', 'text-danger'] }, ` Something went wrong `,);

		/*vm.$bvToast.toast([vNodesMsg] , {
			title:  [vTitle],
			variant: 'danger',
			autoHideDelay: 5000,
			icon: "AlertTriangleIcon",
		  })*/
		vm.$bvToast.toast(text, {
			title: "Something went wrong",
			variant: 'danger',
			autoHideDelay: 5000,
			icon: "AlertTriangleIcon",
		})

		return Promise.reject(error)

	}
)
export const logoutUser = () => {
	localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
	localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
	localStorage.removeItem('userDataAbility')
	localStorage.removeItem('selected-project-id')
	localStorage.removeItem('selected-editor-id')
	authRequest.defaults.headers.Authorization = ''
	axios.defaults.headers.common.Authorization = ''
	store.commit('UserData/UPDATE_USER_DATA', {})
	store.commit('Profile/SET_DATA_TO_PROFILE', {})
	router.push({ name: 'auth-login' }).catch(() => {
	})
}

const errorInterceptor = error => {
	const originalRequest = error.config
	if (error.response) {
		const { status } = error.response
		if (isCorrectRefreshError(status)) {
			return refreshToken()
				.then(() => {
					const headerAuthorization = `Bearer ${window.localStorage.getItem('accessToken')}`
					authRequest.defaults.headers.Authorization = headerAuthorization
					simpleAuthRequest.defaults.headers.Authorization = headerAuthorization
					axios.defaults.headers.common.Authorization = headerAuthorization
					originalRequest.headers.Authorization = headerAuthorization
					return authRequest(originalRequest)
				})
				.catch(tokenRefreshError => {
					logoutUser()
					return Promise.reject(tokenRefreshError)
				})
		}
	}
	return Promise.reject(error)
}

// eslint-disable-next-line arrow-body-style
authRequest.interceptors.response.use(
	response => response,
	error => errorInterceptor(error)
)

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
	return localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

// eslint-disable-next-line vue/no-side-effects-in-computed-properties
export const registerUser = async (firstName, lastName, email, password) => {
	const registerBody = {
		first_name: firstName,
		last_name: lastName,
		email,
		password,
	}
	return tokenRequest
		.post('auth/users/', registerBody)
		.then(res => {
			useJwt.setToken(res.data.access)
			useJwt.setRefreshToken(res.data.refresh)
			localStorage.setItem('refreshValidToken', res.data.refresh)
			axios.defaults.headers.common.Authorization = `Bearer ${res.data.access}`
			return Promise.resolve(res.data)
		})
		.catch(error => {
			const vm = new Vue()
			let statusText = error.response.statusText
			let messageText = error.response.data.email

			vm.$bvToast.toast(messageText, {
				title: statusText,
				variant: 'danger',
				autoHideDelay: 5000,
				icon: "AlertTriangleIcon",
			})
			return Promise.reject(error)
		})
}

export const loginUser = async (email, password) => {
	const loginBody = { email, password }
	return tokenRequest
		.post('auth/jwt/create/', loginBody)
		.then(res => {
			authRequest.defaults.headers.Authorization = `Bearer ${res.data.access}`,
				useJwt.setToken(res.data.access)
			simpleAuthRequest.defaults.headers.Authorization = `Bearer ${res.data.access}`,
				useJwt.setToken(res.data.access)
			useJwt.setRefreshToken(res.data.refresh)
			localStorage.setItem('refreshValidToken', res.data.refresh)
			axios.defaults.headers.common.Authorization = `Bearer ${res.data.access}`
			return Promise.resolve(res.data)
		})
		.catch(error => {
			return Promise.reject(error)
		})
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
	if (userRole === 'admin') return '/projects'
	if (userRole === 'client') return { name: 'access-control' }
	return { name: 'auth-login' }
}

export const errorMessage = (toastr, error) => {
	return
	toastr({
		component: ToastificationContent,
		props: {
			title: "Something went wrong",
			icon: "AlertTriangleIcon",
			variant: "danger",
			text: error.response.data?.name
				? error.response.data?.name
				: "Please try again",
		},
	})
}

import { authRequest, simpleRequest } from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    profileData: {},
    currentDataApi: {},
    isMeAdmin: undefined,
  },
  getters: {
    getProfile: state => state.profileData,
    getDataApi: state => state.currentDataApi,
  },
  mutations: {
    SET_IS_ADMIN(state, response) {
      state.isMeAdmin = response === 'true' ? true : false
    },
    SET_DATA_TO_PROFILE(state, profileData) {
      state.profileData = profileData
    },
    SET_DATA_API(state, val) {
      state.currentDataApi = val
    }
  },
  actions: {
    amIAdmin({ commit }) {
      return new Promise((resolve, reject) => {
        return authRequest.get(`${BASE_URL}is_admin`)
        .then(res => {
          commit('SET_IS_ADMIN', res.data)
          resolve(res)
        })
        .catch(error => reject(error))
      })
    },
    GET_MY_PROFILE({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        return authRequest.get(`${BASE_URL}profile/${rootState.UserData.userData.id}/`)
          .then(res => {
            commit('SET_DATA_TO_PROFILE', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    GET_USER_PROFILE({ commit }, userID) {
      return simpleRequest.get(`${BASE_URL}profile/${userID}/get_public`)
    },

    UPDATE_MY_PROFILE({ commit, rootState }, {first_name, last_name, description, profile_picture, company_name,}) {
      return new Promise((resolve, reject) => {
        authRequest.patch(`${BASE_URL}profile/${rootState.UserData.userData.id}/`, {first_name, last_name, description, profile_picture, company_name,})
          .then(res => {
            commit('SET_DATA_TO_PROFILE', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    UPDATE_MY_PASSWORD({  rootState }, data) {
      return new Promise((resolve, reject) => {
        return authRequest.put(`${BASE_URL}change_password/${rootState.UserData.userData.id}/`, {
          current_password: data.current_password,
          new_password: data.new_password,
        })
        .then(res => {
          resolve(res)
        })
        .catch(error => reject(error))
      })
    },

    LOAD_DATA_API({ commit }) {
      return new Promise((resolve, reject) => {
        fetch("https://extreme-ip-lookup.com/json/")
          .then(res => res.json())
          .then(data => {
            commit('SET_DATA_API', data)
            resolve(data)
          })
          .catch(error => reject(error))
      })
    },
    RESET_EMAIL({ rootState }) {
      return new Promise((resolve, reject) => {
        authRequest.post(`${BASE_URL}auth/users/resend_activation/`, {email: rootState.UserData.userData.email})
          .then(res => {
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

  },
}

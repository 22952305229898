import { authRequest } from '@/auth/utils'


export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    POST_SOURCE_FILE(context, sourceFileData) {
      const formData = new FormData()
      const glbFile = new File([sourceFileData.glbFile], "model.3dg", { type: 'model/gltf-binary', lastModified: new Date().getTime() })
      const jsonFile = new Blob([sourceFileData.jsonData], { type: "application/json" })
      formData.append('glb_file', glbFile)
      formData.append('json_file', jsonFile)
      formData.append('editor', sourceFileData.editorID)

      return authRequest.post('source_file/', formData)
    },
    PATCH_SOURCE_FILE(context, sourceFileData) {
      const formData = new FormData()
      if (sourceFileData.changedData.has('glb') && sourceFileData.glbFile) {
        const glbFile = new File([sourceFileData.glbFile], "model.3dg", { type: 'model/gltf-binary', lastModified: new Date().getTime() })
        formData.append('glb_file', glbFile)
      }
      if (sourceFileData.changedData.has('json') && sourceFileData.jsonData) {
        const jsonFile = new Blob([sourceFileData.jsonData], { type: "application/json" })
        formData.append('json_file', jsonFile)
      }
      formData.append('editor', sourceFileData.editorID)

      return authRequest.patch(`source_file/${sourceFileData.id}/`, formData)
    },
    DELETE_SOURCE_FILE(context, sourceFileID) {
      return authRequest.delete(`source_file/${sourceFileID}`)
    },
  }
}

import { authRequest, simpleRequest } from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    projects: [],
    portfolioProjects: [],
  },
  getters: {
    getProjects: state => state.projects,
    getPortfolioProjecs: state => state.portfolioProjects
  },
  mutations: {
    SET_MY_PROJECTS(state, projects) {
      state.projects = projects
    },
    SET_MY_PORTFOLIO(state, projects) {
      state.portfolioProjects = projects
    },
  },
  actions: {
    GET_MY_PROJECTS({ commit }) { 
      authRequest.get(`${BASE_URL}project/my`)
        .then(res => { 
          commit('SET_MY_PROJECTS', res.data)
        })
    },
    GET_PUBLIC_PROJECTS({ commit }, userID) {
      simpleRequest.get(`${BASE_URL}project/${userID}/public`)
        .then(res => {
          commit('SET_MY_PORTFOLIO', res.data)
        })
    }
  },
}

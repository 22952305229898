import { authRequest } from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    listAnalytics: [],
    logFile: []
  },
  getters: {
    getListAnalytics: state => state.listAnalytics,
  },
  mutations: {
    SET_LIST_ANALYTICS(state, val) {
      state.listAnalytics = val
    },
    SET_LOG_FILE(state, val) {
      state.logFile = val
    }
  },
  actions: {
    setNewAnalytics({  dispatch }, { session, projectUuid, logFile, IP, operatingSystem, browserName, majorVersion, userAgent }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('session', session)
        formData.append('project_uuid', projectUuid)
        formData.append('log_file', logFile)
        formData.append('IP', IP)
        formData.append('operating_system', operatingSystem)
        formData.append('browser_name', browserName)
        formData.append('major_version', majorVersion)
        formData.append('user_agent', userAgent)
        authRequest
          .post(`${BASE_URL}analytics/`, formData)
          .then(res => {
            dispatch('getLogFile', { session, projectUuid, logFile, IP })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    getLogFile({ commit, dispatch }, { session, projectUuid,  IP }) {
      return new Promise((resolve, reject) => {
        authRequest
          .get(`${BASE_URL}analytics/${projectUuid}/${session}/${IP}`)
          .then(res => {
            commit('SET_LOG_FILE', res.data)
            dispatch('getListAnalytics', { projectUuid })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    getListAnalytics({ commit }, projectUuid ) {
      return new Promise((resolve, reject) => {
        authRequest
          .get(`${BASE_URL}analytics/uuid/${projectUuid}`)
          .then(res => {
            commit('SET_LIST_ANALYTICS', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
  },
}

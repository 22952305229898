import { authRequest, simpleAuthRequest, simpleRequest } from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    project: {},
    development_project_files: {},
    left_licenses: {},
  },
  getters: {
    getCurrentProjectFiles: state => state.development_project_files,
    getProject: state => state.project,
    getLeftLicenses: state => state.left_licenses,
  },
  mutations: {
    SET_MY_PROJECT(state, project) {
      state.project = project
    },
    LOAD_PROJECT(state, project) {
      state.project = project
    },
    GET_DEVELOPMENT_PROJECT_FILES(state, paths) {
      state.development_project_files = paths
    },
    SET_LEFT_LICENSES(state, licenses) {
      state.left_licenses = licenses
    },
  },
  actions: {
    getLeftLicenses({ commit }) {
      return new Promise((resolve, reject) => {
        simpleAuthRequest
          .get(`${BASE_URL}user_left_license`)
          .then(res => {
            commit('SET_LEFT_LICENSES', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    setNewProject({ commit, dispatch }, { name, comment, id, collect_analytics, project_type, template, project_license_type }) {
      return new Promise((resolve, reject) => {
        collect_analytics = collect_analytics == true ? true : false;
        template = template? template : null;

        authRequest
          .post(`${BASE_URL}project/`, { name, comment, id, collect_analytics, project_type, template, project_license_type })
          .then(res => {
            commit('SET_MY_PROJECT', res)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    deleteProject({ dispatch }, { projectId }) {
      return new Promise((resolve, reject) => {
        authRequest
          .delete(`${BASE_URL}project/${projectId}`)
          .then(res => {
            dispatch('Projects/GET_MY_PROJECTS', null, { root: true })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    updateProject({ dispatch }, { projectId, name, comment, collect_analytics, project_license_type, is_publish }) {
      return new Promise((resolve, reject) => {
        authRequest
          .put(`${BASE_URL}project/${projectId}/`, { projectId, name, comment, collect_analytics, project_license_type, is_publish })
          .then(res => {
            dispatch('Projects/GET_MY_PROJECTS', null, { root: true })
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    patchProject({ dispatch }, { patchData, projectId }) {
      return new Promise((resolve, reject) => {
        authRequest
          .patch(`${BASE_URL}project/${projectId}/`, patchData)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    loadProject({ commit, dispatch }, projectId) {
      console.log("projectid", projectId)
      return new Promise((resolve, reject) => {
        authRequest
          .get(`${BASE_URL}project/${projectId}`)
          .then(response => {
            commit('LOAD_PROJECT', response.data)
            if (response.data.project_type !== 'external') {
              dispatch('getDevelopmentProjectFiles')
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    getDevelopmentProjectFiles({ state, commit }) {
      return new Promise((resolve, reject) => {
        authRequest
          .get(`${BASE_URL}project/${state.project.uuid}/development`)
          .then(res => {
            commit('GET_DEVELOPMENT_PROJECT_FILES', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    updatePortfolioImage({ state}, {projectID, image}) {
      const formData = new FormData()
      formData.append('portfolio_img_file', image)
      return authRequest.patch(`${BASE_URL}project/${projectID}/portfolio_image/`, formData)
    },
  },
}

import { authRequest, confirmRequest } from '@/auth/utils'

export default {
  namespaced: true,
  state: {
    userData: {},
  },
  getters: {
    getCurrentUser: state => state.userData,
  },
  mutations: {
    UPDATE_USER_DATA(state, data) {
      state.userData = data
    },
  },
  actions: {
    GET_USER_DATA({ commit }) {
      return new Promise((resolve, reject) => {
        authRequest.get('auth/users/me/')
          .then(res => {
            commit('UPDATE_USER_DATA', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    setLanguage({ commit }, lang) {
      return new Promise((resolve, reject) => {
        confirmRequest.post(`setlang/${lang}/`)
          .then(
            res => console.log('res', res)
            // res => resolve(res)
          )
          .catch(error => reject(error))
      })
    },
  },
}

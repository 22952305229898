import { authRequest } from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    templates: [],
    typeMarkets: [],
  },
  getters: {
    getTemplates: state => state.templates,
  },
  mutations: {
    SET_TEMPLATES(state, templates) {
      state.templates = templates
    },
    SET_TYPE_MARKETS(state, templates) {
      let typeMarketsList = []
      templates.forEach(template => {
        if (!typeMarketsList.includes(template.type_market)) {
          typeMarketsList.push(template.type_market)
        } // make a non-repeating list
      })
      state.typeMarkets = [] // make state empty before push
      typeMarketsList.forEach(item => {
        state.typeMarkets.push({value: item, text: item})
      })
    },
  },
  actions: {
    GET_ALL_TEMPLATES({ commit }) { 
        authRequest.get(`${BASE_URL}template/`)
          .then(res => { 
            commit('SET_TEMPLATES', res.data)
            commit('SET_TYPE_MARKETS', res.data)
        })
    },
  }
}

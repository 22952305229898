import { authRequest, } from '@/auth/utils'
import axios from 'axios'


const axiosRequest = axios.create({ Authorization: null, })
axiosRequest.defaults.headers = {
  'Cache-Control': 'no-cache',
}

export default {
  namespaced: true,
  state: {
    sourceFiles: [],
  },
  getters: {
    GET_SOURCE_FILES: state => state.sourceFiles,
    GET_FILTERED_SOURCE_FILES: state => state.sourceFiles.filter(sourceFile => sourceFile.requestType != 'deleting')
  },
  mutations: {
    SET_EDITOR_SOURCE_FILES(state, sourceFiles) {
      state.sourceFiles = sourceFiles
    },
    SET_SOURCE_FILE_GLB(state, { glb, sourceFileID }) {
      const sourceFile = state.sourceFiles.find(sf => sf.id == sourceFileID)
      sourceFile.glb = glb

      // refresh sourceFiles
      state.sourceFiles = state.sourceFiles.filter(sf => sf.id != sourceFileID)
      state.sourceFiles.push(sourceFile)
    },
    SET_SOURCE_FILE_JSON(state, { json, sourceFileID }) {
      const sourceFile = state.sourceFiles.find(sf => sf.id == sourceFileID)
      sourceFile.json = json

      // refresh sourceFiles
      state.sourceFiles = state.sourceFiles.filter(sf => sf.id != sourceFileID)
      state.sourceFiles.push(sourceFile)
    },
    ADD_SOURCE_FILE: (state, sourceFile) => {
      state.sourceFiles.push(sourceFile)
    },
    ADD_DELETE_STATUS_FOR_SOURCE_FILE: (state, sourceFileID) => {
      const sourceFile = state.sourceFiles.find(sf => sf.id == sourceFileID)
      sourceFile.requestType = 'deleting'

      // refresh sourceFiles
      state.sourceFiles = state.sourceFiles.filter(sf => sf.id != sourceFileID)
      state.sourceFiles.push(sourceFile)
    },
    REMOVE_DELETED_SOURCE_FILES: (state) => {
      state.sourceFiles = state.sourceFiles.filter(sf => sf.requestType !== 'deleting')
    },
    REMOVE_SOURCE_FILE: (state, sourceFileID) => {
      state.sourceFiles = state.sourceFiles.filter(sf => sf.id !== sourceFileID)
    },
    RESET_SOURCE_FILES: (state) => {
      state.sourceFiles.forEach(sourceFile => {
        sourceFile.requestType = null
        sourceFile.changedData = null
        sourceFile.isChanged = false
      })
    }
  },
  actions: {
    async LOAD_SOURCE_JSON_FROM_S3(context, json_s3_path) {
      return axiosRequest.get(json_s3_path)
    },
    async LOAD_SOURCE_GLB_FROM_S3(context, glb_s3_path) {
      return axiosRequest.get(glb_s3_path, { responseType: 'arraybuffer' })
    },
    async LOAD_SOURCE_FILES_BY_EDITOR(context, editorID) {
      return authRequest.get(`source_file/${editorID}/by_editor`)
        .then(res => {
          if (res.data) {
            res.data.forEach((sourceFile) => {
              sourceFile.showGlbViewer = true
              if (sourceFile.source_glb_file_s3_path) {
                context.dispatch('LOAD_SOURCE_GLB_FROM_S3', sourceFile.source_glb_file_s3_path).then(res => {
                  context.commit('SET_SOURCE_FILE_GLB', { glb: res.data, sourceFileID: sourceFile.id })
                })
              } else {
                sourceFile.glb = null
              }
              if (sourceFile.source_json_s3_path) {
                context.dispatch('LOAD_SOURCE_JSON_FROM_S3', sourceFile.source_json_s3_path).then(res => {
                  context.commit('SET_SOURCE_FILE_JSON', { json: res.data, sourceFileID: sourceFile.id })
                })
              } else {
                sourceFile.json = {
                  position: { x: 0, y: 0, z: 0 },
                  rotation: { x: 0, y: 0, z: 0 },
                  scale: { x: 1, y: 1, z: 1 },
                }
              }
            })
            context.commit('SET_EDITOR_SOURCE_FILES', res.data)
          } else {
            context.commit('SET_EDITOR_SOURCE_FILES', [])
          }
        })
    },

  }
}

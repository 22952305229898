import { simpleRequest } from '@/auth/utils'
import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    isProjectUpdating: false,
    licensePermissions: {
      "analytics-statistics": {
        "number_users_per_continent": "1 2 3",
        "number_users_per_country": "1 2 3",
        "number_sessions_per_users": "1 2 3",
        "number_sessions_per_browser": "1 2 3",
        "analytics_map": "3"
      },
      "analytics-simulation": "1 2 3",
      "editor-frequency": {
        "frequency_rate": "1 2 3",
        "anti_aliasing": "1 2 3"
      },
      "editor-ar": {
        "google_apple_api": "1 2 3",
        "webxr": "1 2 3"
      },
      "interface-position": {
        "interface_direction": "1 2 3",
        "interface_vertical_position": "1 2 3",
        "interface_horizontal_position": "1 2 3"
      },
      "setting-interface-buttons": {
        "show_interface_button": "1 2 3",
        "show_zoom_button": "1 2 3",
        "show_fullscreen_button": "1 2 3"
      },
      "setting-buttons-design": {
        "buttons_color": "1 2 3",
        "buttons_hover_color": "1 2 3",
        "interface_size": "1 2 3"
      },
      "mirror-effect": "1 2 3",
      "camera-movement": {
        "unlimited_movement": "1 2 3",
        "vertical_rotation": "1 2 3",
        "horizontal_rotation": "1 2 3"
      },
      "team": {
        "invite_1_coowner": "1 2 3",
        "invite_10_coowners": "1 2 3"
      },
      "project-type": {
        "internal": "1 2 3",
        "external": "1 2 3"
      }
    },
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    LICENSE_PERMISSIONS: state => {
      return state.licensePermissions
    }
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_IS_PROJECT_UPDATING(state, val) {
      state.isProjectUpdating = val
    },
    SET_LICENSE_PERMISSIONS(state, val) {
      state.licensePermissions = val
    },
  },
  actions: {
    GET_LICENSE_PERMISSIONS(context) {
      simpleRequest.get("/license_permissions").then(response => {
        context.commit("SET_LICENSE_PERMISSIONS", response.data)
      })
    }
  },
}

import {authRequest} from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    team: [],
  },
  getters: {
    getTeam: state => state.team,
  },
  mutations: {
    SET_MY_TEAM(state, team) {
      state.team = team
    },
  },
  actions: {
    GET_MY_USERS({commit}, projectId) {
      return new Promise((resolve, reject) => {
        authRequest.get(`${BASE_URL}project/${projectId}/users`)
          .then(res => {
            commit('SET_MY_TEAM', res.data)
            resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    setNewMember({ commit, dispatch }, { emails, id }) {
      return new Promise((resolve, reject) => {
        authRequest
          .post(`${BASE_URL}co_owners/${id}/add/`, {emails})
          .then(res => {
            commit('SET_MY_TEAM', res)
            dispatch('GET_MY_USERS', id)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    deleteCoOwner({dispatch}, {projectId, email}) {
      return new Promise((resolve, reject) => {
        authRequest
          .delete(`${BASE_URL}co_owners/${projectId}`, {
            data: {
              emails: email
            },
          })
          .then(res => {
            dispatch('GET_MY_USERS', projectId)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
  },
}

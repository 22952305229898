import { authRequest } from '@/auth/utils'


export default {
  namespaced: true,
  state: {
    editorEffects: [],
  },
  getters: {
    EDITOR_EFFECTS: state => state.editorEffects,
    FILTERED_EDITOR_EFFECTS: state => state.editorEffects.filter(effect => effect.deleted != true)
  },
  mutations: {
    SET_EDITOR_EFFECTS: (state, effects) => {
        effects.forEach((effect) => {
            try {
                effect.meshes_list_json = JSON.parse(effect.meshes_list_json)
            } catch {
                console.warn("Error: meshes_list_json has wrong format:", effect.meshes_list_json)
            }

            effect.customHDRFiles = []
            effect.hdrNumbers = []
			effect.hadCustomHDR = effect.has_custom_hdr ? true : false
       })
       state.editorEffects = effects
    },
    ADD_EDITOR_EFFECT: (state, effectData) => {
        state.editorEffects.push(effectData)
    },
	ADD_HAD_HDR_STATUS_FOR_EFFECT: (state, {effectID, status}) => {
		const effect = state.editorEffects.find(effect => effect.id === effectID)
        effect.hadCustomHDR = status

        // Code below for store rective updating
        state.editorEffects = state.editorEffects.filter(effect => effect.id != effectID)
        state.editorEffects.push(effect)
	},
    ADD_DELETE_STATUS_FOR_EFFECT: (state, effectID) => {
        const effect = state.editorEffects.find(effect => effect.id === effectID)
        effect.deleted = true

        // Code below for store rective updating
        state.editorEffects = state.editorEffects.filter(effect => effect.id != effectID)
        state.editorEffects.push(effect)
    },
    REMOVE_EDITOR_EFFECT: (state, effectID) => {
        state.editorEffects = state.editorEffects.filter(effect => effect.id != effectID)
    },
    SET_EFFECT_HDR_NUMBERS: (state, {effectID, numbers}) => {
        const effect = state.editorEffects.find(effect => effect.id === effectID)
        effect.hdrNumbers = numbers

        // Code below for store rective updating
        state.editorEffects = state.editorEffects.filter(effect => effect.id != effectID)
        state.editorEffects.push(effect)
    },
  },
  actions: {
    LOAD_EFFECTS_BY_EDITOR(context, editorID) {
        return authRequest.get(`effect/${editorID}/by_editor`)
            .then(res => {
                if (res.data) {
                    context.commit('SET_EDITOR_EFFECTS', res.data)
                    setTimeout(() => {
                        const effects = context.getters['EDITOR_EFFECTS']
                        effects.forEach(effect => {
                            if (effect.has_custom_hdr) {
                                context.dispatch('LOAD_NUMBERS_HDRS_BY_EFFECT', effect.id)
                                    .then((res) => {
                                        if (res.data) context.commit('SET_EFFECT_HDR_NUMBERS', {
                                            effectID: effect.id, numbers: res.data
                                        })
                                    })
                            }
                        })
                    }, 1000)
                    
                } else {
                    context.commit('SET_EDITOR_EFFECTS', [])
                }
            })
            .catch((err) => {
                console.error(err)
            })
    },
    LOAD_NUMBERS_HDRS_BY_EFFECT(context, effectID) {
        return authRequest.get(`hdr/${effectID}/numbers_by_effect`)
    }
  }
}

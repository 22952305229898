import { authRequest } from '@/auth/utils'
import store from '@/store'


const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    editor: {},
    editors: [],
    showAnimation: false,
    sceneChanged: false,
    firstChanged: false,
    uploadPercentage: 0,
    cameraSetting: {},
    editorConfig: { caller: 'editor', isSelected: true },
    modelLoading: false,
    backgroundImage: null,
  },
  getters: {
    getEditor: state => state.editor,
    getEditors: state => state.editors,
    getStateAnimation: state => state.showAnimation,
    getBackgroundImage: state => state.backgroundImage,
  },
  mutations: {
    SET_EDITOR(state, editor) {
      state.editor = editor
    },
    SET_EDITORS(state, editors) {
      state.editors = editors
    },
    SET_SHOW_ANIMATION(state, val) {
      state.showAnimation = val
    },
    SET_MODEL_LOADING(state, val) {
      state.modelLoading = val
    },
    SET_UPLOAD_PERCENTAGE(state, val) {
      state.uploadPercentage = val
    },
    SET_CALLER_EDITOR(state) {
      state.editorConfig = { caller: 'editor', isSelected: true }
    },
    SET_CALLER_INTERACTION(state, val) {
      state.editorConfig = { caller: 'interaction', isSelected: val }
    },
    SET_CAMERA(state, val) {
      state.cameraSetting = val
    },
    SET_SCENE_CHANGE_STATUS(state, val) {
      state.sceneChanged = val
    },
    SET_SCENE_FIRST_CHANGE(state, val) {
      state.firstChanged = val
    },
    SET_BACKGROUND_IMAGE(state, val) {
      state.backgroundImage = val
    },
  },
  actions: {
    setNewEditor({ commit, dispatch }, { version, projectId, json_file, staging, production, current, }) {
      const formData = new FormData()
      formData.append('version', version)
      formData.append('projectId', projectId)
      formData.append('json_file', json_file)
      formData.append('staging', staging)
      formData.append('production', production)
      formData.append('current', current)

      return authRequest
        .post(`${BASE_URL}editor/`, formData)
        .then(res => {
          commit('SET_EDITOR', res.data)
          dispatch('getProjectEditors', projectId)
        })
        .catch(error => console.log(error))
    },

    deleteEditor({ dispatch }, { editorId, projectId }) {
      return authRequest
        .delete(`${BASE_URL}editor/${editorId}`)
        .then(() => {
          dispatch('getProjectEditors', projectId)
        })
        .catch(error => console.log(error))
    },

    getEditor({ commit, dispatch }, editorId) {
      return new Promise((resolve, reject) => {
        authRequest
          .get(`${BASE_URL}editor/${editorId}`)
          .then(res => {
            commit('SET_EDITOR', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    getProjectEditors({ commit }, id) {
      return new Promise((resolve, reject) => {
        authRequest
          .get(`${BASE_URL}project/${id}/editor/`)
          .then(res => {
            commit('SET_EDITORS', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    updateGlbEditor({ commit, state }, { glbFile }) {
      commit('SET_SHOW_ANIMATION', true)
      store.commit('app/SET_IS_PROJECT_UPDATING', true)
      return new Promise((resolve, reject) => {
        // get camera 
        const cameraSettingData = JSON.stringify(state.cameraSetting)
            
        const formData = new FormData()

        if (glbFile) {
          const fileGLB = new File([glbFile], "name.3dg", { type: 'model/gltf-binary', lastModified: new Date().getTime() })
          formData.append('glb_file', fileGLB)
        }
        if (cameraSettingData) {
          const cameraSettingFile = new Blob([cameraSettingData], { type: "application/json" }) 
          formData.append("settings_json_file", cameraSettingFile)
        }

        return authRequest
          .patch(BASE_URL + "editor/" + state.editor.id + "/",
            formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
              commit('SET_UPLOAD_PERCENTAGE', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
            }
          }
          )
          .then(res => {
            resolve(res)
            commit('SET_UPLOAD_PERCENTAGE', 0)
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            commit('SET_SHOW_ANIMATION', false)
            store.commit('app/SET_IS_PROJECT_UPDATING', false)
          })
      })
    },

    updateHDRFile({ state }, hdrObjects) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (hdrObjects.file_HDR_Background) {
          const backHdrFile = new File([hdrObjects.file_HDR_Background], "name.hdr")
          formData.append('background_hdr_file', backHdrFile)
        }
        if (hdrObjects.file_HDR_Environment) {
          const envHdrFile = new File([hdrObjects.file_HDR_Environment], "name.hdr")
          formData.append('environment_hdr_file', envHdrFile)
        }

        return authRequest
          .patch(`${BASE_URL}editor/${state.editor.id}/`,
            formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
          )
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    removeHDRFile({ state }, type) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()

        if (type == 'env') {
          formData.append('environment_hdr_file', '')
        } else if (type == 'back') {
          formData.append('background_hdr_file', '')
        }

        return authRequest
          .patch(`${BASE_URL}editor/${state.editor.id}/`,
            formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
          )
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    updateBackgroundImageFile({ state }, image) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        const envHdrFile = new File([image], image.name)
        formData.append('background_img_file', image)
        return authRequest
          .patch(`${BASE_URL}editor/${state.editor.id}/`,
            formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
          )
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    async removeBackgroundImageFile({ state }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('background_img_file', '')
        return authRequest
          .patch(`${BASE_URL}editor/${state.editor.id}/`,
            formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
          )
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    updateEditorStatus({ development, staging, production, editorId }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('development', development)
        formData.append('staging', staging)
        formData.append('production', production)
        return authRequest
          .patch(`${BASE_URL}editor/${editorId}/`, formData)
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
    encryptFile(glbFile) {
      var ciphertext = this.CryptoJS.AES.encrypt(glbFile, '123')
    },
    setCameraSetting({ rootState }) {
      return fetch(rootState.Editor.editor.settings_json_s3_path, {
        method: "GET",
        cache: "no-cache",
      })
    },
    updateInjectedCode({ state }, codeDictionary) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        for (let code in codeDictionary) {
          formData.append(code, codeDictionary[code])
        }

        return authRequest
          .patch(`${BASE_URL}editor/${state.editor.id}/`,
            formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              onUploadProgress: function (progressEvent) {
              }
            }
          ).then(res => {
            resolve(res)
          }).catch(error => {
            reject(error)
          }).finally()
      })
    },
    changeSetting({ state }, id) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        const camera = state.cameraSetting;
        const cameraSettingData = JSON.stringify(camera);
        const cameraSettingFile = new Blob([cameraSettingData], { type: "application/json" })
        formData.append("settings_json_file", cameraSettingFile)
        return authRequest
          .patch(BASE_URL + "editor/" + id + "/",
            formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (progressEvent) {
            }
          }
          )
          .then(res => {
            resolve(res)

          })
          .catch(error => {
            reject(error)
          })
          .finally()
      })
    },


  },
}

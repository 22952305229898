import { authRequest } from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    license: {},
  },
  getters: {
    getLicense: state => state.license,
  },
  mutations: {
    SET_USER_LICENSE(state, license) {
      state.license = license;
    },
  },
  actions: {
    GET_USER_LICENSE({ commit }, licenseID) {
      authRequest.get(`${BASE_URL}user_current_license/${licenseID}`)
        .then(res => {
          commit('SET_USER_LICENSE', res.data)
        })
    },

    PATCH_USER_LICENSE({ dispatch }, licenseData) {
      const formData = new FormData()
      formData.append('paid_date', licenseData.paid_date)
      formData.append('basic_count', licenseData.basic_count)
      formData.append('plus_count', licenseData.plus_count)
      formData.append('pro_count', licenseData.pro_count)
      formData.append('payment_frequency', licenseData.payment_frequency)

      authRequest
        .patch(`${BASE_URL}user_current_license/${licenseData.id}/`, formData)
        .then(() => {
          dispatch('PATCH_USER_UNLIMITED_LICENSE', { userID: licenseData.user.id, hasUnlimitedLicenses: licenseData.user.has_unlimited_licenses})
            .then(() => {
              dispatch('GET_USER_LICENSE', licenseData.id)
            })
        })
    },

    PATCH_USER_UNLIMITED_LICENSE({ commit }, { userID, hasUnlimitedLicenses}) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('has_unlimited_licenses', hasUnlimitedLicenses)

        authRequest
          .patch(`${BASE_URL}profile/set_unlimited_licenses/${userID}/`, formData)
            .then(res => resolve(res))
      })
    }
  },
}

import { authRequest } from '@/auth/utils'

export default {
  namespaced: true,
  state: {
    all: [],
  },
  getters: {
    getAllNitification: state => state.all,
  },
  mutations: {
    UPDATE_NOTIFICATION(state, data) {
      state.all = data
    },
  },
  actions: {
    GET_NOTIFICATION({ commit }) {
      return new Promise((resolve, reject) => {
        authRequest.get('notifications')
          .then(res => {
            commit('UPDATE_NOTIFICATION', res.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    READ_ALL_NOTIFICATION() {
      return new Promise((resolve, reject) => {
        authRequest.patch('notifications/readall/')
          .then(res => {
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
  },
}

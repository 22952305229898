import { authRequest } from '@/auth/utils'

const BASE_URL = process.env.VUE_APP_REVOLVA_API_CONFIGURATOR_URL

export default {
  namespaced: true,
  state: {
    licenses: [],
  },
  getters: {
    getLicenses: state => state.licenses,
  },
  mutations: {
    SET_LICENSES(state, licenses) {
        state.licenses = licenses
    },
  },
  actions: {
    GET_ALL_LICENSES({ commit }) {
        authRequest.get(`${BASE_URL}user_current_license/all`)
            .then(res => {
                commit('SET_LICENSES', res.data)
            })
    }

  },
}
